<template>
  <div class="common-box" :class="isFullScreen ? 'shuiping' : 'chuizhi'">
    <div ref="imgDom" class="imgDom">
      <!-- 标题 -->
      <div class="common-title">
        近{{ chartTime == 0.875 ? 3 : chartTime == 0.75 ? 6 : chartTime == 0.5 ? 12 : 24 }}小时血糖曲线
        <div class="hitten" v-show="!isLandscape">（横屏效果更佳）</div>
      </div>
      <div v-show="chartData" id="container" ref="container" style="width: 100%; height: 25rem"></div>
      <div style="padding: 1rem 0; margin-top: -10px">
        <van-radio-group v-model="chartTime" @change="getDateArray(chartTime)" direction="horizontal">
          <van-radio :name="0.875">
            3H
            <template #icon="props">
              <img v-if="props.checked" class="img-icon" src="../../../static/pic/timesed.png" />
              <img v-else class="img-icon" src="../../../static/pic/timenosed.png" />
            </template>
          </van-radio>
          <van-radio :name="0.75">
            6H
            <template #icon="props">
              <img v-if="props.checked" class="img-icon" src="../../../static/pic/timesed.png" />
              <img v-else class="img-icon" src="../../../static/pic/timenosed.png" />
            </template>
          </van-radio>
          <van-radio :name="0.5">
            12H
            <template #icon="props">
              <img v-if="props.checked" class="img-icon" src="../../../static/pic/timesed.png" />
              <img v-else class="img-icon" src="../../../static/pic/timenosed.png" />
            </template>
          </van-radio>
          <van-radio :name="0">
            24H
            <template #icon="props">
              <img v-if="props.checked" class="img-icon" src="../../../static/pic/timesed.png" />
              <img v-else class="img-icon" src="../../../static/pic/timenosed.png" />
            </template>
          </van-radio>
        </van-radio-group>
      </div>

      <!-- <ns-pages :isIndex="isIndex" /> -->
    </div>
  </div>
</template>
<script>
  import { debounce } from 'lodash'
  import NsPages from '@/pages/nsPages'
  // import { Line, G2, Scatter } from '@antv/g2plot'
  import { Mix, G2, Line, Scatter } from '@antv/g2plot'
  import yds from '@/static/pic/loopYDS.png'
  import { loopHistory } from '@/api/relationship'
  import { getSugerbyId, getEvent, getAllTempArry } from '@/api/userServices'
  import { loopTreatments, getLoopHandleList } from '../../../api/relationship'
  import { mapState, mapActions, mapMutations } from 'vuex'
  const G = G2.getEngine('canvas')
  export default {
    components: { NsPages },
    props: {
      // userId: {
      //   type: String,
      //   default: '',
      // },
    },
    data() {
      return {
        isLandscape: false,
        chartTime: '',
        btnNum: '0.875',
        userId: JSON.parse(localStorage.getItem('appuser')).id,
        isFullScreen: false,
        minTime: '',
        tickInterval: '',
        scatterPlot: null,
        scatterPlot2: null,
        tempPlot: null,
        chartData: [],
        lineData: [],
        eventArr: [], // 糖仁云添加事件列表
        tempBasal: [], // 临时基础率
        time: 0.875, // 时间段选择
        timer: null, // 5分钟一次的定时器
        timerNum: 0,
        startEventTime: '',
        CorrectionBolus: [], // 胰岛素
        SuspendPump: [], // 泵
        CarbCorrection: [], // 碳水
        TemporaryOverride: [], // 覆盖方波
        loopType: '',
        maxRate: '',
        loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
        // 下面是socket相关参数
        socketId: JSON.parse(localStorage.getItem('appuser')).id,
      }
    },
    mounted() {
      this.timer = setInterval(() => {
        this.timerNum++
        // this.getDateArray(this.time)
      }, 5 * 60 * 1000)

      this.getData().then(() => {
        this.getAllEvent().then(() => {
          this.getChart()
          this.chartTime = 0.875
        })
      })

      //定义回调函数
      this.$ws.type1 = debounce(this.getAllData, 2000)

      this.chartHeight = window.innerHeight

      this.checkOrientation()
      window.addEventListener('resize', this.checkOrientation)
      window.addEventListener('orientationchange', this.checkOrientation)
    },
    beforeDestroy() {
      this.$ws.type1 = null
      clearInterval(this.timer)
      window.removeEventListener('resize', this.checkOrientation)
      window.removeEventListener('orientationchange', this.checkOrientation)
    },
    methods: {
      checkOrientation() {
        if (window.innerWidth > window.innerHeight) {
          this.isLandscape = true
          this.$store.commit('setLandscape', this.isLandscape)
        } else {
          this.isLandscape = false
          this.$store.commit('setLandscape', this.isLandscape)
        }
      },
      getDateArray(time) {
        this.btnNum = time
        if (time == 3) {
          this.minTime = this.minTime3
          this.tickInterval = 30 * 60 * 1000
        } else if (time == 6) {
          this.minTime = this.minTime6
          this.tickInterval = 60 * 60 * 1000
        } else if (time == 12) {
          this.minTime = this.minTime12
          this.tickInterval = 2 * 60 * 60 * 1000
        } else if (time == 24) {
          this.minTime = this.minTime24
          this.tickInterval = 4 * 60 * 60 * 1000
        }
      },
      // 格式化时间
      formatTime(timestamp) {
        const date = new Date(timestamp)
        return this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss') // 返回 'YYYY-MM-DDTHH:mm:ss' 格式
      },
      // 获取当前时间时间戳
      parseTime(time) {
        return new Date(time).getTime()
      },
      // 数组去重
      removeDuplicates(arr, key) {
        const seen = new Set()
        return arr.filter((item) => {
          const val = item[key]
          if (seen.has(val)) {
            return false
          } else {
            seen.add(val)
            return true
          }
        })
      },
      // // 血糖事件集合数组按照事件排序
      sortTime(obj) {
        obj.sort((a, b) => {
          let t1 = new Date(Date.parse(a.create_time.replace(/-/g, '/')))
          let t2 = new Date(Date.parse(b.create_time.replace(/-/g, '/')))
          return t1.getTime() - t2.getTime()
        })
        return obj
      },
      // websoket来信息调用
      getAllData() {
        this.mixPlot.destroy()
        this.getData().then(() => {
          this.getAllEvent().then(() => {
            this.getChart()
          })
        })
      },
      // 获取所有事件
      getAllEvent() {
        return new Promise((resolve) => {
          this.eventArr = []
          // 获取基础率和临时基础率组合数组

          const tempParams = {
            uid: this.userId,
            end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            start: this.$moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            type: this.loopRemote,
          }
          let params = {
            uid: this.userId,
            create_time: this.$moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            type: this.loopRemote,
          }
          loopHistory(params).then((res) => {
            this.$emit('loopInfo', res)
          })
          getAllTempArry(tempParams).then((res) => {
            // 基础率组合数组
            this.allTempArray = res ? res.data.data : []
            // 获取 ns 事件
            const start3 = this.$moment(this.minTime3).format('YYYY-MM-DD HH:mm:ss')
            const start6 = this.$moment(this.minTime6).format('YYYY-MM-DD HH:mm:ss')
            const start12 = this.$moment(this.minTime12).format('YYYY-MM-DD HH:mm:ss')
            const start24 = this.$moment(this.minTime24).format('YYYY-MM-DD HH:mm:ss')
            const nsParams = {
              uid: this.userId,
              start:
                this.chartTime == 0.875
                  ? start3
                  : this.chartTime == 0.75
                  ? start6
                  : this.chartTime == 0.5
                  ? start12
                  : this.chartTime == 0
                  ? start24
                  : start3,
              end: this.$moment(this.maxTime).format('YYYY-MM-DD HH:mm:ss'),
              type: this.loopRemote,
            }
            loopTreatments(nsParams).then(({ data }) => {
              data.treatments.forEach((item) => {
                if (item.eventType === 'Temp Basal') {
                }
                // 胰岛素
                else if (item.eventType === 'Correction Bolus' || item.eventType === 'Meal Bolus') {
                  if (item.insulin) {
                    item.createTime = item.create_time
                    item.v = 0
                    item.type = 'Correction Bolus'
                    this.eventArr.push(item) // 添加ns事件---胰岛素
                  }
                }
                // 碳水分组
                else if (item.eventType === 'Carb Correction') {
                  item.createTime = item.create_time
                  item.v = -1
                  item.type = item.eventType
                  this.eventArr.push(item) // 添加ns事件---碳水
                }
                // 覆盖方波分组
                else if (
                  this.loopRemote == 'loop'
                    ? item.eventType === 'Temporary Override'
                    : item.eventType === 'Temporary Target'
                ) {
                  item.createTime = item.create_time
                  item.v = -2
                  item.type = 'Temporary Override'
                  item.duration = item.durationInMilliseconds
                    ? (item.durationInMilliseconds / 1000 / 60).toFixed(1)
                    : (item.duration * 1).toFixed(1)
                  this.eventArr.push(item) // 添加ns事件---覆盖方波
                }
                // 泵分组
                else if (
                  this.loopRemote == 'loop' ? item.eventType === 'Suspend Pump' : item.eventType === 'OpenAPS Offline'
                ) {
                  item.createTime = item.create_time
                  item.v = -2
                  item.type = 'Suspend Pump'
                  this.eventArr.push(item)
                }
                // 其他
                // else {
                //   item.createTime = item.create_time;
                //   item.v = -2;
                //   item.type = item.eventType;
                //   this.eventArr.push(item);
                // }
              })

              this.eventArr.forEach((event) => {
                event.create_time = event.createTime
                event.type = event.type
                event.tooltip_time = event.createTime.substring(11, 16)
                event.v = 1

                this.chartData.push(event)
              })
              this.sortTime(this.chartData)
              let v2 = ''
              let v1 = ''
              this.chartData.forEach((item, index) => {
                // 把时间、血糖合并数组里的血糖先挑出来处理
                if (item.type != 'sugar') {
                  if (item.type == 'Temporary Override') {
                    item.v = 18.5
                  } else {
                    for (let i = index - 1; i >= 0; i--) {
                      if (this.chartData[i].type == 'sugar') {
                        v1 = this.chartData[i].v
                        break
                      }
                    }

                    for (let i = index + 1; i <= this.chartData.length - 1; i++) {
                      if (this.chartData[i].type == 'sugar') {
                        v2 = this.chartData[i].v
                        break
                      }
                    }
                    if (v1 && v2) {
                      item.v = (v1 + v2) / 2
                    } else if (v1) {
                      item.v = v1
                    } else if (v2) {
                      item.v = v2
                    }
                  }
                }
                // 把每个点（包括事件，否则可能会图表错乱）都复值前一个基础率
                const time = this.$moment(item.create_time).valueOf()
                this.allTempArray.forEach((temp, i) => {
                  const preTime = this.$moment(temp.timestamp).valueOf()
                  const nextTime = this.allTempArray[i + 1]
                    ? this.$moment(this.allTempArray[i + 1].timestamp).valueOf()
                    : null
                  if ((preTime <= time && nextTime >= time) || (preTime <= time && !nextTime)) {
                    item.tempValue = temp.value
                    item.tempType = temp.type
                  }
                })
              })
              setTimeout(() => {
                if (this.mixPlot) {
                  // this.mixPlot.changeData(this.chartData)
                }
                resolve()
              }, 100)
            })
          })
        })
      },

      getChart() {
        const data = this.removeDuplicates(this.chartData, 'create_time')

        this.mixPlot = new Mix('container', {
          appendPadding: 8,
          padding: [10, 10, 60, 20],
          interactions: [{ type: 'tooltip' }],
          syncViewPadding: true,
          slider: {
            visible: false,
            start: 0,
            end: 1,
            height: 30,
            textStyle: {
              fillOpacity: 0,
            },
            trendCfg: {
              lineStyle: {
                stroke: '#5b8ff9',
                lineWidth: 2,
                lineDash: [4, 5],
                strokeOpacity: 0.7,
                shadowColor: '#5b8ff9',
                shadowBlur: 10,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
                cursor: 'pointer',
              },
            },
            handlerStyle: {
              width: 0,
              height: 0,
              opacity: 0,
            },
          },
          data,
          supportCSSTransform: true,
          tooltip: {
            showTitle: false,
            crosshairs: {
              type: 'x',
              follow: true,
            },
            interactions: [{ type: 'tooltip' }],
            position: 'top',
            customContent: (title, data) => {
              let loopType = title ? (data[0].data.json ? JSON.parse(data[0].data.json).type : '') : ''
              let type = ''
              let eventType = title ? data[0].data.type : ''
              let res = title ? data[0].data : ''
              let parse = title ? (data[0].data.json ? JSON.parse(data[0].data.json) : '') : ''
              if (title && data[0].data.type === 'zx') {
                type = '指尖血'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 场景：${res.eventValue}</div>
                        <div style="margin-bottom:4px;">指血指数：${res.way}mmol/L</div>
                      </div>`
              } else if (title && data[0].data.type === 'kfy') {
                type = '口服药'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 用药时段：${res.scene}</div>
                        <div style="margin-bottom:4px;"> 药名：${res.way}</div>
                        <div style="margin-bottom:4px;"> 用量：${res.eventValue}</div>
                      </div>`
              } else if (title && data[0].data.type === 'ts') {
                type = '碳水'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> ${res.eventValue}</div>
                      </div>`
              } else if (title && data[0].data.type === 'yds') {
                type = '胰岛素'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 注射时段：${res.scene}</div>
                        <div style="margin-bottom:4px;"> 胰岛素名称：${res.eventValue}</div>
                        <div style="margin-bottom:4px;"> 注射剂量：${res.way}U</div>
                      </div>`
              } else if (title && data[0].data.type === 'yd') {
                type = '运动'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 运动时常：${res.scene}h</div>
                        <div style="margin-bottom:4px;"> 消耗：${res.eventValue}kcal</div>
                        <div style="margin-bottom:4px;"> 备注：${res.way}</div>
                      </div>`
              } else if (title && loopType == 1) {
                type = '关闭闭环'
              } else if (title && loopType == 2) {
                type = '开启闭环'
              } else if (title && loopType == 3) {
                type = '覆盖方波'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 持续时间：${parse.minutes}</div>
                        <div style="margin-bottom:4px;"> 原因：${parse.fugainame}</div>
                      </div>`
              } else if (title && loopType == 4) {
                type = '取消方波'
              } else if (title && loopType == 5) {
                type = '添加碳水'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 总量：${parse.carbs}g</div>
                        <div style="margin-bottom:4px;"> 吸收时间：${parse.carbsHouers}h</div>
                      </div>`
              } else if (title && loopType == 6) {
                type = '大剂量'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 剂量：${parse.bolus}U</div>
                      </div>`
              } else if (title && loopType == 7) {
                type = '临时基础率'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 临时基础率：${parse.perHour}U/h</div>
                        <div style="margin-bottom:4px;"> 持续时间：${parse.baseHouers}h</div>
                      </div>`
              } else if (title && loopType == 8) {
                type = '取消临时'
              } else if (title && eventType == 'Correction Bolus') {
                type = '胰岛素'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 剂量：${res.insulin}U</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 名称：${res.insulinType || '无'}</div>
                      </div>`
              } else if (title && eventType == 'Carb Correction') {
                type = '碳水'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 克数：${res.carbs ? res.carbs + 'g' : '无'}</div>
                        <div style="margin-bottom:4px;"> 类型：${res.foodType ? res.foodType : '无'}</div>
                      </div>`
              } else if (title && eventType == 'Temporary Override') {
                type = '覆盖方波'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 需求比例：${
                          res.insulinNeedsScaleFactor ? res.insulinNeedsScaleFactor : '无'
                        }</div>
                        <div style="margin-bottom:4px;"> 持续时间：${res.duration}分钟</div>
                        <div style="margin-bottom:4px;"> 原因：${res.reason}</div>
                      </div>`
              } else if (title && eventType == 'Suspend Pump') {
                type = '泵'
              } else if (title && eventType == 'sugar') {
                type = '血糖'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}:${data[0].data.v}</div>
                        <div style="margin-bottom:2px;font-size:10px"> 基础率:${
                          data[0].data.tempValue == 0 ? 0 : data[0].data.tempValue || '无'
                        }</div>
                        <div style="margin-bottom:2px;font-size:10px"> ${title}</div>

                      </div>`
              } else {
                type = '其他'
              }
              return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div> ${title}</div>
                      </div>`
            },
          },
          plots: [
            {
              // type: this.chartTime == 0.875 || this.chartTime == 0.75 ? 'scatter' : 'line',
              type: 'scatter',
              top: true,

              options: {
                xField: 'create_time',
                yField: 'v',
                size: this.chartTime == 0.875 ? 3 : this.chartTime == 0.75 ? 2 : this.chartTime == 0.5 ? 1.5 : 1,
                shape: 'circle',
                pointStyle: {
                  stroke: null, // 移除边框
                  fillOpacity: 1, // 填充不透明
                },
                colorField: 'v',
                color: ({ v }) => {
                  return v > 10 ? '#F4664A' : v > 3.9 ? '#6395fa' : v > 0 ? '#ffaa29' : '#f8f8f8' // y 值大于 25 时颜色为红色，否则为蓝色
                },
                // 隐藏颜色控件图例
                legend: {
                  visible: false,
                },
                supportCSSTransform: true,
                xAxis: {
                  // visible: false,
                  type: 'time',
                  max: this.maxTime,
                  min: this.minTime,
                  tickInterval: this.tickInterval,
                  mask: 'HH:mm', // 显示格式为小时:分钟
                  // range: [0, 1], // 刻度范围为0到1
                  tickMethod: (scale) => {
                    const { min, max, tickCount } = scale
                    // const avg = (max - min) / tickCount;
                    const ticks = []
                    for (let i = min; i <= max; i += this.tickInterval) {
                      ticks.push(i)
                    }
                    return ticks
                  },
                },

                yAxis: {
                  grid: null,
                  line: null,
                  min: 0,
                  max: 20,
                  tickCount: 5,
                  nice: false,
                },

                label: {
                  offsetY: 0,
                  content: (item) => {
                    this.loopType = item.json ? JSON.parse(item.json).type : ''
                    if (item.type == 'kfy') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 6,
                          height: 6,
                          img: require('@/static/pic/kfytr.png'),
                        },
                      })
                      // group.addShape({
                      //   type: 'text',
                      //   attrs: {
                      //     x: 6,
                      //     y: 2,
                      //     text: item.type,
                      //     textAlign: 'center',
                      //     textBaseline: 'top',
                      //     fill: '#000'
                      //   }
                      // })
                      return group
                    } else if (item.type == 'ts') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 6,
                          height: 6,
                          img: 'https://i.postimg.cc/sxTXkccS/01.png',
                        },
                      })
                      return group
                    } else if (item.type == 'yds') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 6,
                          height: 6,
                          img: require('@/static/pic/loopYDS.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'yd') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 6,
                          height: 6,
                          img: 'https://i.postimg.cc/rwxTWjrJ/image.png',
                        },
                      })
                      return group
                    } else if (item.type == 'zx') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 6,
                          height: 6,
                          img: require('@/static/pic/zxtr.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'Correction Bolus') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 2,
                          y: 16,
                          width: 1 * item.insulin < 1 ? 4 : 4 + 1.4 * item.insulin,
                          height: 1 * item.insulin < 1 ? 4 : 4 + 1.4 * item.insulin,
                          img: yds,
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x: 8,
                          y: 1 * item.insulin < 1 ? 18 : 1 * item.insulin < 6 ? 8 : 6,
                          fontSize: 1 * item.insulin < 1 ? 5 : 5 + 0.8 * item.insulin,
                          text: (item.insulin * 1).toFixed(1) + 'U',
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: 1 * item.insulin > 1 ? '#000' : '#424242',
                        },
                      })
                      return group
                    } else if (item.type == 'Carb Correction') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 2,
                          y: 16,
                          width: this.chartTime == 0 ? 6 : this.chartTime == 0.5 ? 7 : 8,
                          height: this.chartTime == 0 ? 6 : this.chartTime == 0.5 ? 7 : 8,
                          img: require('@/static/pic/tstr.png'),
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x: 6,
                          y: 21,
                          fontSize: 6,
                          text: item.carbs + 'g',
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: '#424242',
                        },
                      })
                      return group
                    } else if (item.type == 'Temporary Override') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x:
                            this.chartTime == 0.875
                              ? item.duration * 3
                              : this.chartTime == 0.75
                              ? (item.duration * 3) / 2
                              : this.chartTime == 0.5
                              ? (item.duration * 1.5) / 2
                              : ((item.duration / 4) * 3) / 2,
                          y: 12,
                          width:
                            this.chartTime == 0.875
                              ? item.duration * 6
                              : this.chartTime == 0.75
                              ? item.duration * 3
                              : this.chartTime == 0.5
                              ? item.duration * 1.5
                              : (item.duration / 4) * 3,
                          height: 12,
                          img: require('@/static/pic/override.png'),
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x:
                            this.chartTime == 0.875
                              ? item.duration * 3 + 18
                              : this.chartTime == 0.75
                              ? (item.duration * 3) / 2 + 18
                              : this.chartTime == 0.5
                              ? (item.duration * 1.5) / 2 + 18
                              : ((item.duration / 4) * 3) / 2 + 18,
                          y: 12,
                          fontSize: 8,
                          text: item.reason,
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: '#000',
                        },
                      })
                      return group
                    } else if (item.type == 'Suspend Pump') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 10,
                          width: this.chartTime == 0 ? 8 : this.chartTime == 0.5 ? 10 : 13,
                          height: this.chartTime == 0 ? 8 : this.chartTime == 0.5 ? 10 : 13,
                          img: require('@/static/pic/pump.png'),
                        },
                      })
                      return group
                    }
                  },
                  labelLine: true,
                },
                annotations: [
                  {
                    type: 'region',
                    // start: ["min", this.targetLow],
                    // end: ["max", this.targetHigh],
                    start: ['min', 3.9],
                    end: ['max', 10],
                    style: {
                      stroke: '#edf0f2',
                      lineDash: [2, 2],
                    },
                  },

                  {
                    type: 'text',
                    position: ['min', 10],
                    // content: this.targetHigh,
                    content: 10,
                    style: {
                      fill: '#F4664A',
                    },
                  },

                  {
                    type: 'text',
                    // position: ["min", this.targetLow],
                    // content: this.targetLow,
                    content: 3.9,
                    position: ['min', 3.9],
                    style: {
                      fill: '#ffaa29',
                    },
                  },
                  // {
                  //   type: 'regionFilter',
                  //   start: ['min', 0],
                  //   end: ['max', 3.9],
                  //   color: '#ffaa29',
                  // },
                  // {
                  //   type: 'regionFilter',
                  //   start: ['min', 10],
                  //   end: ['max', 20],
                  //   color: '#F4664A',
                  // },
                ],
              },
            },
            {
              type: 'line',
              top: true,
              options: {
                xField: 'create_time',
                yField: 'tempValue',
                stepType: 'vh',
                connectNulls: true,
                lineStyle: {
                  lineWidth: 1,
                },
                xAxis: {
                  // visible: false,
                  type: 'time',
                  max: this.maxTime,
                  min: this.minTime,
                  tickInterval: this.tickInterval,
                  mask: 'HH:mm', // 显示格式为小时:分钟
                  // range: [0, 1], // 刻度范围为0到1
                  tickMethod: (scale) => {
                    const { min, max, tickCount } = scale
                    // const avg = (max - min) / tickCount;
                    const ticks = []
                    for (let i = min; i <= max; i += this.tickInterval) {
                      ticks.push(i)
                    }
                    return ticks
                  },
                },
                yAxis: {
                  min: 0,
                  max: 8,
                  label: null,
                  nice: false,
                  // tickMethod: () => [ 1, 2, 3],
                },
              },
            },
          ],
          xAxis: {
            // visible: false,
            type: 'time',
            max: this.maxTime,
            min: this.minTime,
            tickInterval: this.tickInterval,
            mask: 'HH:mm', // 显示格式为小时:分钟
            // range: [0, 1], // 刻度范围为0到1
            tickMethod: (scale) => {
              const { min, max, tickCount } = scale
              // const avg = (max - min) / tickCount;
              const ticks = []
              for (let i = min; i <= max; i += this.tickInterval) {
                ticks.push(i)
              }
              return ticks
            },
          },
        })
        this.$nextTick(() => {
          this.mixPlot.render()
        })

        if (this.loopRemote) {
          this.$emit('getTemp', data[data.length - 1].tempValue, data[data.length - 1].tempType)
        }

        this.mixPlot.on('slider:touchmove', (...args) => {
          this.handleMove(args[0])
        })

        this.mixPlot.on('plot:touchmove', (event) => {
          const { x } = event
          // 获取点图中的所有数据点
          const elements = this.mixPlot.chart.geometries[0].elements
          let closestElement = null
          let minDistance = Infinity

          elements.forEach((element) => {
            const bbox = element.shape.getCanvasBBox()
            const elementX = (bbox.minX + bbox.maxX) / 2
            const distance = Math.abs(x - elementX)

            if (distance < minDistance) {
              minDistance = distance
              closestElement = element
            }
          })

          if (closestElement) {
            this.mixPlot.chart.showTooltip({
              x: (closestElement.shape.getCanvasBBox().minX + closestElement.shape.getCanvasBBox().maxX) / 2,
              y: (closestElement.shape.getCanvasBBox().minY + closestElement.shape.getCanvasBBox().maxY) / 2,
            })
          } else {
            this.mixPlot.chart.hideTooltip()
          }
        })

        // 确保触摸移出图表时隐藏 tooltip
        this.mixPlot.on('plot:touchend', () => {
          this.mixPlot.chart.hideTooltip()
        })
      },

      getData() {
        return new Promise((resolve) => {
          this.chartData = []
          const params = {
            id: this.userId,
            end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            start: this.$moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          }

          getSugerbyId(params).then((res) => {
            if (res.data.data && res.data.data.length) {
              res.data.data.forEach((item) => {
                this.chartData.push({
                  create_time: item.create_time,
                  v: item.v,
                  tooltip_time: item.create_time.substring(11, 16),
                  type: 'sugar',
                })
              })

              // 补全血糖数组
              const result = []
              const FIVE_MINUTES = 5 * 60 * 1000
              for (let i = 0; i < this.chartData.length - 1; i++) {
                result.push(this.chartData[i])
                let currentTime = this.parseTime(this.chartData[i].create_time)
                const nextTime = this.parseTime(this.chartData[i + 1].create_time)

                // 如果相差大于 5 分钟，则补充中间缺失的数据
                while (nextTime - currentTime > FIVE_MINUTES * (result.length - i)) {
                  const newTime = currentTime + FIVE_MINUTES * (result.length - i)
                  result.push({
                    create_time: this.formatTime(newTime),
                    v: 0,
                    type: 'sugar',
                    tooltip_time: this.formatTime(newTime).substring(11, 16),
                  })
                }
              }
              result.push(this.chartData[this.chartData.length - 1])
              this.chartData = result

              const length = res.data.data.length

              if (length > 1) {
                if (res.data.data[length - 1].v > res.data.data[length - 2].v) {
                  this.trend = 1
                } else if (res.data.data[length - 1].v < res.data.data[length - 2].v) {
                  this.trend = 2
                } else {
                  this.trend = 0
                }
                const leftSugar = (res.data.data[length - 1].v - res.data.data[length - 2].v).toFixed(1)
                const lastTime = res.data.data[length - 1].create_time
                this.$emit('getTrend', this.trend, res.data.data[length - 1].v, leftSugar, lastTime)
              }
            } else {
              this.isHaveSugar = false
            }
            resolve()
          })
        })
      },
    },
    computed: {
      maxTime() {
        const nextTime = this.$moment()
          .add(1 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(nextTime).getTime()
      },
      minTime3() {
        const min = this.$moment()
          .subtract(2 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime6() {
        const min = this.$moment()
          .subtract(5 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime12() {
        const min = this.$moment()
          .subtract(11 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime24() {
        const min = this.$moment()
          .subtract(23 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
    },

    watch: {
      // 每10秒自动渲染一次chart2，要先清除
      timerNum: {
        handler: function (newVal, oldVal) {
          this.$nextTick(() => {
            this.getAllData()
          })
        },
      },

      btnNum: {
        handler: function (newVal, oldVal) {
          this.chartTime = newVal
          this.sliderStart = this.chartTime
          this.sliderEnd = 1
          if (this.chartTime == 0.875) {
            this.minTime = this.minTime3
            this.tickInterval = 30 * 60 * 1000
          } else if (this.chartTime == 0.75) {
            this.minTime = this.minTime6
            this.tickInterval = 60 * 60 * 1000
          } else if (this.chartTime == 0.5) {
            this.minTime = this.minTime12
            this.tickInterval = 2 * 60 * 60 * 1000
          } else if (this.chartTime == 0) {
            this.minTime = this.minTime24
            this.tickInterval = 4 * 60 * 60 * 1000
          }
          this.$nextTick(() => {
            this.getAllData()
          })
        },
      },
    },
  }
</script>
<style scoped>
  * {
    list-style: none;
  }
  .common-box {
    width: 96%;
    margin: 0.6rem;
    border-radius: 1.2rem;
    background: rgb(255, 255, 255);
    /* box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16); */
    position: relative;
  }
  .common-title {
    display: flex;
    align-items: flex-end;
    height: 3.4rem;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: start;
    line-height: 3.4rem;
    /* border-bottom: 1px solid #ccc; */
    padding-left: 1rem;
    background: aliceblue;
  }
  .van-radio-group--horizontal {
    justify-content: center;
  }
  .chartCan {
    width: 100%;
    /* background: #d3eeff; */
    position: absolute;
    /* border-top: 2px dotted #c1b8b8;
  border-bottom: 2px dotted #c1b8b8; */
    height: 4.8rem;
    bottom: 9rem;
  }
  .bottom-num {
    position: absolute;
    right: 5px;
    bottom: -16px;
    color: red;
  }
  .top-num {
    position: absolute;
    right: 5px;
    top: 0px;
    color: orange;
  }
  .unit {
    position: absolute;
    left: 5px;
    margin-top: 5px;
  }
  .van-radio__label {
    color: #7accff;
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .handleShow {
    position: absolute;
    right: 1rem;
    top: 0.4rem;
    width: 30px;
    height: 30px;
  }
  .handleShow img {
    width: 25px;
    height: 25px;
  }
  .shuiping {
    width: 100vh;
    height: 100vw;
    border-radius: 0;
    /* box-shadow: 0; */
    position: absolute;
    top: 49%;
    left: 48%;
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: 9999;
    /* 阻止屏幕拖动 */
    overflow: hidden;
    touch-action: none;
  }
  .imgDom > li {
    position: absolute;
    top: 4rem;
  }
  .imgDom > li img {
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  .imgDom > li > div {
    display: none;
    position: absolute;
    left: -12px;
    top: 17px;
    width: 40px;
    height: 40px;
    background-color: black;
    color: #fff;
    border-radius: 5px;
  }
  /* 事件标记样式 */
  .marker {
    position: absolute;
    top: 2.5rem;
    z-index: 99;
    width: 100%;
    height: 7rem;
  }
  /* 梯形图样式 */
  #temp {
    position: absolute;
    width: 100%;
    height: 55px;
    bottom: 55px;
    z-index: 99;
  }
  .hitten {
    font-size: 15px;
  }
</style>
